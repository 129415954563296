html,
body,
#root {
    height: 100vh;
    overflow: hidden;
    font-family: Poppins;
}

* {
    font-family: Poppins;
}

.blueProgressBar .ant-slider-handle {
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.blueProgressBar .ant-slider:hover .ant-slider-handle {
    opacity: 1;
}

.blueProgressBar .ant-slider-track {
    background-color: #4285f4;
}

.blueProgressBar .ant-slider:hover .ant-slider-track {
    background-color: #4285f4;
}