.playButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #4285f4;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: 0 10px;
    margin-right: 15px;
}

.playButton i {
    color: white;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}

.rewindButton {
    color: black;
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.volumeButton i,
.rewindButton i {
    font-size: 0.9em;
    color: black;
    width: 14px;
    height: 12px;
}

.volumeButton {
    color: black;
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    padding: 0 5px;
    border-radius: 20px;
}

.volumeSlider {
    width: 80px;
    display: none;
    height: 20px;
    margin-top: -12px;
}

.volumeButton:hover .volumeSlider {
    display: block;
}

.volumeButton:hover {
    border: 1px solid #ddd;
}

.volumeButton:hover>i {
    color: #4285f4;
}

.ProgressBar {
    flex: 1;
}

.duration {
    line-height: 30px;
    font-weight: 500;
}